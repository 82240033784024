import { Plugin } from '@nuxt/types';

declare module 'vue/types/vue' {
	interface Vue {
		$getDomain(): string;
		$isDomain(tld: string): boolean;
	}
}

declare module '@nuxt/types' {
	// nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
	interface NuxtAppOptions {
		$getDomain(): string;
		$isDomain(tld: string): boolean;
	}
	// nuxtContext.$myInjectedFunction
	interface Context {
		$getDomain(): string;
		$isDomain(tld: string): boolean;
	}
}

const extractDomain = (host: string): string => {
	const url = host.split(':')[0];
	let tld = url.split('.').pop() || 'at';

	// tmp fix for healthchecks where the page is requested via IP-address
	if (!['at', 'de', 'cz', 'hu', 'sk'].includes(tld)) {
		if (process.env.BU) {
			tld = process.env.BU.toLowerCase();
		} else {
			tld = 'at';
		}
	}

	return tld;
};

const DomDetectPlugin: Plugin = ({ req }: any, inject) => {
	const host = process.server ? req.headers.host : window.location.host;

	const domain = extractDomain(host);

	// Potentially sets default locale here depending on domain ?

	// Inject $getDomain(value) in Vue, context and store.
	// (nuxt build in method - Avoid using Vue.prototype as it apparently causes memory leaks)
	inject('getDomain', (): string => {
		return domain;
	});

	inject('isDomain', (tld: string): boolean => {
		return domain === tld.toLowerCase();
	});
};

export default DomDetectPlugin;
export { extractDomain };
