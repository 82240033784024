
import Vue from 'vue';
import { VueConstructor } from 'vue/types';
import type { MetaInfo } from 'vue-meta';
// import { tabChangeTitleListener } from '@/_helpers/misc_helper';
import localeHandler from '@/mixins/localeHandler';

import { JsonLD } from '@/types/misc_data';
import { routesNames } from '@/router';

import UTILS from '@/store/modules/UtilityModule';
import USER from '@/store/modules/UserModule';
import CART from '@/store/modules/CartModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import AppDownload from '@/components/dialogs/AppDownload.vue';

import global from '~/mixins/global';
Vue.mixin(global);

export default (Vue as VueConstructor<Vue & InstanceType<typeof localeHandler>>).extend({
	name: 'App',

	components: { AppDownload },
	mixins: [localeHandler, global],

	data() {
		return {
			data_ready: false,
			offset_top: 0,
			showDownloadDialog: false
		};
	},

	// Nuxt method head() uses vue-meta to update the headers and html attributes.
	head(): MetaInfo {
		return {
			titleTemplate: '123-Transporter | %s',
			title: this.currentMetaData ? this.currentMetaData.title.content : '123-Transporter',
			htmlAttrs: {
				lang: this.currentLang
			},
			meta: [this.currentMetaData ? this.currentMetaData.desc : {}, this.currentMetaData ? this.currentMetaData.mainDesc : {}],
			script: this.currentScripts,
			__dangerouslyDisableSanitizersByTagID: {
				'google-analytics': ['innerHTML'],
				'fb-pixel': ['innerHTML'],
				mouseflow: ['innerHTML'],
				gtm: ['innerHTML'],
				'google-tag-manager': ['innerHTML']
			},
			link: [this.canonicalLink]
		};
	},

	computed: {
		UTILS: () => UTILS,
		USER: () => USER,
		MISC_DATA: () => MISC_DATA,
		CART: () => CART,

		currentScripts(): any[] {
			// sets default jsonld or stored one if component has a custom one
			let scripts = this.MISC_DATA.html_header_base_scripts as any;

			if (process.env.NODE_ENV === 'production') {
				if (this.$isDomain('de')) {
					scripts = this.MISC_DATA.html_header_prod_scripts_de;
				} else if (this.$isDomain('cz')) {
					scripts = this.MISC_DATA.html_header_prod_scripts_cz;
				} else {
					scripts = this.MISC_DATA.html_header_prod_scripts;
				}
			}

			// add override to block google-fonts
			const block_google_fonts_script = {
				vmid: 'block_google_fonts_script',
				id: 'block_google_fonts_script',
				innerHTML: `

				var head = document.getElementsByTagName('head')[0];
				// set default value to track if the method was replaced
				head._scriptReplaced = head._scriptReplaced ?? false;

				// only continue if the replacement is not done yet
				if(!head._scriptReplaced){

					const isGoogleFont = function (element) {
						// google font download
						if (element.href
							&& element.href.indexOf('https://fonts.googleapis.com') === 0) {
							return true;
						}       
						return false;
					}

					// we override these methods only for one particular head element
					// default methods for other elements are not affected
					const insertBefore = head.insertBefore;
					head.insertBefore = function (newElement, referenceElement) {
						if (!isGoogleFont(newElement)) {
							// console.log(newElement);
							insertBefore.call(head, newElement, referenceElement);
						}
					};

					// set prop to remember the replacement
					head._scriptReplaced = true;
				}
				`
			};
			scripts = [block_google_fonts_script, ...scripts];

			if (this.chatScript && this.chatScript.length > 0) {
				scripts = [...scripts, ...this.chatScript];
			}

			const jsonld = {
				type: 'application/ld+json',
				json: new JsonLD()
			};

			if (this.$route.name === routesNames.faq || this.$route.name === routesNames.landing_page) {
				jsonld.json = this.MISC_DATA.jsonld;
			}

			return [...scripts, ...[jsonld]];
		},

		chatScript(): any[] {
			// only load on CZ desktop
			if (this.$isDomain('cz') && !this.isMobile) {
				return [this.MISC_DATA.chatling_script];
			}

			return [];
		},

		bookingFocusMode(): boolean {
			const focus_routes = [routesNames.upsells_1, routesNames.upsells_2, routesNames.summary];
			return focus_routes.includes(this.$route.name as routesNames);
		},

		pageLoading(): boolean {
			return this.UTILS.page_loading;
		},

		redirectLoading(): boolean {
			return this.UTILS.redirect_loading;
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		test(): string {
			return this.$route.path;
		},

		canonicalLink(): any {
			if (!this.$route.path) {
				return undefined;
			}
			return {
				hid: 'page-canonical',
				rel: 'canonical',
				href: this.$config[`url_${this.$getDomain()}`] + this.$route.path
			};
		},

		currentLang(): string {
			const tld = this.$getDomain();
			if (tld === 'cz') {
				return 'cs';
			}
			return tld;
		},

		routeName(): string {
			const r = this.$route.name?.toLowerCase();
			return r?.replace(/\s/g, '') as string;
		},

		currentMetaData(): any {
			// UTILS.meta_data is filled in the fetchTranslation middleware so we have metadata in each languages.
			return (this.MISC_DATA.meta_data as any)[this.routeName];
		},

		showNavBar(): boolean {
			if (this.bookingFocusMode) {
				return false;
			}
			return !(this.$route.name === routesNames.info && !USER.data.is_complete);
		},

		showFooter(): boolean {
			if (this.bookingFocusMode) {
				return false;
			}
			const r = this.$route.name;
			return r !== routesNames.upsells_1 && r !== routesNames.upsells_2 && r !== routesNames.info && r !== routesNames.summary && r !== routesNames.checkout;
		},
		showBookingFooter(): boolean {
			return this.bookingFocusMode && (this.$getDomain() === 'at' || this.$getDomain() === 'de');
		}
	},

	async mounted() {
		this.data_ready = true;
		const availableLocales = this.$i18n.locales;

		if (!this.$isDomain('hu')) {
			const tldParams = this.getParamsFromURL();
			if (tldParams) {
				const lang = tldParams.substring(0, 2);

				if (lang === 'en') {
					const domainName: string = this.$getDomain();
					const createLocale = lang + '-' + domainName.toUpperCase();

					const localeExist = availableLocales.some((locale) => typeof locale === 'object' && locale.code === createLocale);

					if (localeExist) {
						this.changeLocale(createLocale);
					}
				}
			}
		}

		const discountC = this.getDiscountCode();

		if (discountC) {
			sessionStorage.setItem('discountCode', discountC);
		}

		if (this.$route.query.dialog) {
			// clear dialog queries in case of reload
			this.$logger.console({ message: 'clear dialog queries in case of reload' });
			this.$router.push({ path: this.$route.path });
		}

		if (this.$route.name !== routesNames.home && localStorage.getItem('booking') !== null && !(await this.CART.CHECK_BOOKING())) {
			this.$logger.console({
				message: 'Stored booking invalid - cleared everything and redirecting home'
			});
			this.$router.replace({ name: routesNames.home });
		}

		this.MISC_DATA.storeBaseUrl({
			at: this.$config.url_at,
			de: this.$config.url_de,
			cz: this.$config.url_cz,
			hu: this.$config.url_hu,
			sk: this.$config.url_sk
		});

		// tabChangeTitleListener(); // tmp disable to check if this is the event causing the memory leak

		let api_base_url = this.$config.apiBaseUrl;

		if (api_base_url) {
			// This needs to be there for the auth.
			// The Top Level Domain of the api call needs to be the same as the Top Level Domain of the currently served webiste.
			api_base_url = api_base_url.replace('.at', `.${this.$getDomain()}`);
			this.$axios.defaults.baseURL = api_base_url;
		}

		// Enable Convert
		this.$nextTick(function () {
			if (typeof (window as any).convert !== 'undefined') {
				const exp = (window as any).convert.currentData.experiments;
				for (const expID in exp) {
					(window as any)._conv_q = (window as any)._conv_q || [];
					(window as any)._conv_q.push(['executeExperiment', expID]);
				}
			}
		});

		//	Convert Local Testing
		// @ts-ignore
		// 	window.convert = {
		//     "T": {},
		//     "activeLocations": {},
		//     "utils": {
		//         "JSON": {}
		//     },
		//     "isRedirect": false,
		//     "data": {
		//         "u_id": "100411946",
		//         "prj": {
		//             "utc_of": "0",
		//             "extset": {
		//                 "ga": {
		//                     "on": true,
		//                     "type": "ga4",
		//                     "measurementId": ""
		//                 },
		//                 "ecommerce": true,
		//                 "d_anon": true,
		//                 "autlnk": false,
		//                 "gdprw": true,
		//                 "dnt": "0",
		//                 "maxordv": 99999,
		//                 "minvis": 10000,
		//                 "minconv": 100,
		//                 "stopTrackingGoalsAfterDays": 30,
		//                 "srm_check": false,
		//                 "smart_recommendations": true,
		//                 "stats_engine_processing": {
		//                     "stats_type": "frequentist",
		//                     "power": 80,
		//                     "test_type": "one_tail",
		//                     "multiple_comparison_correction": "sidak",
		//                     "power_calculation_type": "none",
		//                     "fixed_mde": 1,
		//                     "decision_threshold": 95,
		//                     "risk_threshold": 5
		//                 },
		//                 "mindays": 999,
		//                 "csmb": "$",
		//                 "version": "2025-01-03T10:50:58+00:00-131",
		//                 "gaUA": [],
		//                 "minordv": 0,
		//                 "cname": null
		//             },
		//             "custom_domain": [],
		//             "id": "100412226",
		//             "name": "Project #100412226",
		//             "global_d": {
		//                 "js": "",
		//                 "css": ""
		//             },
		//             "domains": {
		//                 "123-transporter.at": [
		//                     "123-transporter.at"
		//                 ],
		//                 "123-transporter.de": [
		//                     "123-transporter.de"
		//                 ],
		//                 "123-transporter.cz": [
		//                     "123-transporter.cz"
		//                 ],
		//                 "123-transporter.hu": [
		//                     "123-transporter.hu"
		//                 ]
		//             },
		//             "domainsCount": 4,
		//             "asoc_domains": {
		//                 "123-transporter.at": "123-transporter.at",
		//                 "123-transporter.de": "123-transporter.de",
		//                 "123-transporter.cz": "123-transporter.cz",
		//                 "123-transporter.hu": "123-transporter.hu"
		//             }
		//         },
		//         "experiments": {
		//             "1004122960": {
		//                 "n": "Test #1004122960",
		//                 "v": "10",
		//                 "id": 1004122960,
		//                 "t_r_a": [],
		//                 "t_r_f": [],
		//                 "t_seg": [],
		//                 "t_ad_r": [],
		//                 "locs": [
		//                     "1004100929"
		//                 ],
		//                 "tp": "3",
		//                 "integr": {
		//                     "ga": {
		//                         "on": true,
		//                         "type": "ga4",
		//                         "measurementId": "G-WN57SMVTYM"
		//                     }
		//                 },
		//                 "s": "2",
		//                 "global_d": {},
		//                 "sets": {
		//                     "maxordv": 99999
		//                 },
		//                 "vars": {
		//                     "1004291682": {
		//                         "p": 50,
		//                         "secs": [],
		//                         "content": {
		//                             "js": [],
		//                             "html": []
		//                         },
		//                         "chng": [],
		//                         "active": 0,
		//                         "name": "Var #1004291682"
		//                     },
		//                     "1004291683": {
		//                         "p": 50,
		//                         "secs": [
		//                             ""
		//                         ],
		//                         "content": {
		//                             "js": [],
		//                             "html": []
		//                         },
		//                         "chng": [
		//                             "Version 2"
		//                         ],
		//                         "active": 1,
		//                         "settings": [],
		//                         "name": "Var #1004291683"
		//                     }
		//                 },
		//                 "vars_sort": [
		//                     1004291682,
		//                     1004291683
		//                 ]
		//             },
		//             "1004124806": {
		//                 "n": "Test #1004124806",
		//                 "v": "10",
		//                 "id": 1004124806,
		//                 "t_r_a": [],
		//                 "t_r_f": [],
		//                 "t_seg": [],
		//                 "t_ad_r": [],
		//                 "locs": [
		//                     "1004102126"
		//                 ],
		//                 "tp": "3",
		//                 "integr": {
		//                     "ga": {
		//                         "on": true,
		//                         "type": "ga4",
		//                         "measurementId": "G-WN57SMVTYM"
		//                     }
		//                 },
		//                 "s": "1",
		//                 "global_d": {
		//                     "css": ""
		//                 },
		//                 "sets": {
		//                     "minordv": 0,
		//                     "maxordv": 99999
		//                 },
		//                 "vars": {
		//                     "1004295894": {
		//                         "p": "50.00",
		//                         "secs": [
		//                             ""
		//                         ],
		//                         "content": {
		//                             "js": [],
		//                             "html": []
		//                         },
		//                         "chng": [
		//                             "Version 1"
		//                         ],
		//                         "active": 1,
		//                         "settings": [],
		//                         "name": "Var #1004295894"
		//                     },
		//                     "1004295895": {
		//                         "p": "50.00",
		//                         "secs": [
		//                             ""
		//                         ],
		//                         "content": {
		//                             "js": [
		//                                 {
		//                                     "w": 2
		//                                 },
		//                                 {
		//                                     "w": 1
		//                                 },
		//                                 {
		//                                     "w": 3,
		//                                     "s": ".transparent:nth-child(3) > .v-card__text"
		//                                 }
		//                             ],
		//                             "html": []
		//                         },
		//                         "chng": [
		//                             "Version 2"
		//                         ],
		//                         "active": 1,
		//                         "settings": [],
		//                         "name": "Var #1004295895"
		//                     }
		//                 },
		//                 "vars_sort": [
		//                     1004295894,
		//                     1004295895
		//                 ]
		//             }
		//         },
		//         "goals": {
		//             "100464662": {
		//                 "add": [
		//                     [
		//                         [
		//                             {
		//                                 "entid": 16,
		//                                 "compid": "3",
		//                                 "dn": null,
		//                                 "not": 1,
		//                                 "data": 1,
		//                                 "repeat": 1,
		//                                 "timesearch": 0,
		//                                 "visitsCountSearch": 0
		//                             },
		//                             {
		//                                 "entid": 18,
		//                                 "compid": "3",
		//                                 "dn": null,
		//                                 "not": 1,
		//                                 "data": 10,
		//                                 "repeat": 1,
		//                                 "timesearch": 0,
		//                                 "visitsCountSearch": 0
		//                             }
		//                         ]
		//                     ]
		//                 ],
		//                 "vpoints": "0",
		//                 "tp": 0,
		//                 "sts": [],
		//                 "isbounce": 1
		//             },
		//             "100470924": {
		//                 "add": [],
		//                 "vpoints": "0",
		//                 "tp": 4,
		//                 "sts": [],
		//                 "isbounce": 0,
		//                 "ga_event": "purchase"
		//             },
		//             "100471623": {
		//                 "add": [],
		//                 "vpoints": "0",
		//                 "tp": 4,
		//                 "sts": [],
		//                 "isbounce": 0,
		//                 "ga_event": "slot_confirm"
		//             }
		//         },
		//         "specgoals": {
		//             "bounce": 100464662
		//         },
		//         "segments": [],
		//         "entities": {
		//             "1": {
		//                 "entity_id": "1",
		//                 "group_id": "1",
		//                 "nice_name": "browser"
		//             },
		//             "2": {
		//                 "entity_id": "2",
		//                 "group_id": "1",
		//                 "nice_name": "browserV"
		//             },
		//             "3": {
		//                 "entity_id": "3",
		//                 "group_id": "1",
		//                 "nice_name": "os"
		//             },
		//             "4": {
		//                 "entity_id": "4",
		//                 "group_id": "1",
		//                 "nice_name": "flash"
		//             },
		//             "5": {
		//                 "entity_id": "5",
		//                 "group_id": "1",
		//                 "nice_name": "java"
		//             },
		//             "6": {
		//                 "entity_id": "6",
		//                 "group_id": "1",
		//                 "nice_name": "screenColor"
		//             },
		//             "7": {
		//                 "entity_id": "7",
		//                 "group_id": "1",
		//                 "nice_name": "screenResolution"
		//             },
		//             "10": {
		//                 "entity_id": "10",
		//                 "group_id": "2",
		//                 "nice_name": "landingPage"
		//             },
		//             "11": {
		//                 "entity_id": "11",
		//                 "group_id": "3",
		//                 "nice_name": "keyword"
		//             },
		//             "12": {
		//                 "entity_id": "12",
		//                 "group_id": "3",
		//                 "nice_name": "sourceName"
		//             },
		//             "13": {
		//                 "entity_id": "13",
		//                 "group_id": "3",
		//                 "nice_name": "referalMedium"
		//             },
		//             "14": {
		//                 "entity_id": "14",
		//                 "group_id": "3",
		//                 "nice_name": "referalPath"
		//             },
		//             "15": {
		//                 "entity_id": "15",
		//                 "group_id": "5",
		//                 "nice_name": "dayHour"
		//             },
		//             "16": {
		//                 "entity_id": "16",
		//                 "group_id": "4",
		//                 "nice_name": "pageDepth"
		//             },
		//             "17": {
		//                 "entity_id": "17",
		//                 "group_id": "4",
		//                 "nice_name": "daysLastVisit"
		//             },
		//             "18": {
		//                 "entity_id": "18",
		//                 "group_id": "4",
		//                 "nice_name": "visitDuration"
		//             },
		//             "19": {
		//                 "entity_id": "19",
		//                 "group_id": "4",
		//                 "nice_name": "timeOnPage"
		//             },
		//             "20": {
		//                 "entity_id": "20",
		//                 "group_id": "4",
		//                 "nice_name": "avgTimeOnPAge"
		//             },
		//             "21": {
		//                 "entity_id": "21",
		//                 "group_id": "4",
		//                 "nice_name": "visitKnown"
		//             },
		//             "22": {
		//                 "entity_id": "22",
		//                 "group_id": "4",
		//                 "nice_name": "countVisits"
		//             },
		//             "24": {
		//                 "entity_id": "24",
		//                 "group_id": "4",
		//                 "nice_name": "city"
		//             },
		//             "25": {
		//                 "entity_id": "25",
		//                 "group_id": "4",
		//                 "nice_name": "country"
		//             },
		//             "26": {
		//                 "entity_id": "26",
		//                 "group_id": "4",
		//                 "nice_name": "language"
		//             },
		//             "27": {
		//                 "entity_id": "27",
		//                 "group_id": "4",
		//                 "nice_name": "region"
		//             },
		//             "28": {
		//                 "entity_id": "28",
		//                 "group_id": "4",
		//                 "nice_name": "segment"
		//             },
		//             "29": {
		//                 "entity_id": "29",
		//                 "group_id": "2",
		//                 "nice_name": "queryString"
		//             },
		//             "31": {
		//                 "entity_id": "31",
		//                 "group_id": "2",
		//                 "nice_name": "pageUrl"
		//             },
		//             "33": {
		//                 "entity_id": "33",
		//                 "group_id": "4",
		//                 "nice_name": "visitorPoints"
		//             },
		//             "34": {
		//                 "entity_id": "34",
		//                 "group_id": "5",
		//                 "nice_name": "weekDay"
		//             },
		//             "35": {
		//                 "entity_id": "35",
		//                 "group_id": "5",
		//                 "nice_name": "ptzWeekDay"
		//             },
		//             "36": {
		//                 "entity_id": "36",
		//                 "group_id": "5",
		//                 "nice_name": "ptzDayHour"
		//             },
		//             "37": {
		//                 "entity_id": "37",
		//                 "group_id": "5",
		//                 "nice_name": "minute"
		//             },
		//             "38": {
		//                 "entity_id": "38",
		//                 "group_id": "5",
		//                 "nice_name": "tzMinute"
		//             },
		//             "39": {
		//                 "entity_id": "39",
		//                 "group_id": "6",
		//                 "nice_name": "v0"
		//             },
		//             "40": {
		//                 "entity_id": "40",
		//                 "group_id": "6",
		//                 "nice_name": "v1"
		//             },
		//             "41": {
		//                 "entity_id": "41",
		//                 "group_id": "6",
		//                 "nice_name": "v2"
		//             },
		//             "42": {
		//                 "entity_id": "42",
		//                 "group_id": "6",
		//                 "nice_name": "v3"
		//             },
		//             "43": {
		//                 "entity_id": "43",
		//                 "group_id": "6",
		//                 "nice_name": "v4"
		//             },
		//             "44": {
		//                 "entity_id": "44",
		//                 "group_id": "6",
		//                 "nice_name": "v41"
		//             },
		//             "45": {
		//                 "entity_id": "45",
		//                 "group_id": "6",
		//                 "nice_name": "v5"
		//             },
		//             "46": {
		//                 "entity_id": "46",
		//                 "group_id": "6",
		//                 "nice_name": "cv1"
		//             },
		//             "47": {
		//                 "entity_id": "47",
		//                 "group_id": "6",
		//                 "nice_name": "cv2"
		//             },
		//             "48": {
		//                 "entity_id": "48",
		//                 "group_id": "6",
		//                 "nice_name": "cv3"
		//             },
		//             "49": {
		//                 "entity_id": "49",
		//                 "group_id": "6",
		//                 "nice_name": "cv4"
		//             },
		//             "50": {
		//                 "entity_id": "50",
		//                 "group_id": "2",
		//                 "nice_name": "pageUrl1"
		//             },
		//             "51": {
		//                 "entity_id": "51",
		//                 "group_id": "1",
		//                 "nice_name": "useragent"
		//             },
		//             "52": {
		//                 "entity_id": "52",
		//                 "group_id": "4",
		//                 "nice_name": "testedVisitor"
		//             },
		//             "53": {
		//                 "entity_id": "53",
		//                 "group_id": "4",
		//                 "nice_name": "cookie"
		//             },
		//             "54": {
		//                 "entity_id": "54",
		//                 "group_id": "6",
		//                 "nice_name": "jscondition"
		//             },
		//             "55": {
		//                 "entity_id": "55",
		//                 "group_id": "1",
		//                 "nice_name": "deviceMobile"
		//             },
		//             "56": {
		//                 "entity_id": "56",
		//                 "group_id": "1",
		//                 "nice_name": "deviceTablet"
		//             },
		//             "57": {
		//                 "entity_id": "57",
		//                 "group_id": "1",
		//                 "nice_name": "deviceDesktop"
		//             },
		//             "58": {
		//                 "entity_id": "58",
		//                 "group_id": "3",
		//                 "nice_name": "referalCampaign"
		//             },
		//             "59": {
		//                 "entity_id": "59",
		//                 "group_id": "7",
		//                 "nice_name": "weatherCondition"
		//             },
		//             "60": {
		//                 "entity_id": "60",
		//                 "group_id": "4",
		//                 "nice_name": "goal"
		//             },
		//             "61": {
		//                 "entity_id": "61",
		//                 "group_id": "4",
		//                 "nice_name": "bucketedSegment"
		//             }
		//         },
		//         "eclean": [],
		//         "locations": {
		//             "1004100929": {
		//                 "r": [
		//                     [
		//                         [
		//                             {
		//                                 "entid": "50",
		//                                 "compid": "4",
		//                                 "not": false,
		//                                 "data": "https://123-transporter.cz/",
		//                                 "repeat": null,
		//                                 "timesearch": null,
		//                                 "visitsCountSearch": null
		//                             },
		//                             {
		//                                 "entid": "50",
		//                                 "data": "https://stag.123-transporter.cz/",
		//                                 "not": false,
		//                                 "compid": "4",
		//                                 "repeat": null,
		//                                 "timesearch": null,
		//                                 "visitsCountSearch": null
		//                             }
		//                         ]
		//                     ]
		//                 ],
		//                 "n": "Location #1004100929"
		//             },
		//             "1004102126": {
		//                 "r": [
		//                     [
		//                         [
		//                             {
		//                                 "entid": "50",
		//                                 "compid": "4",
		//                                 "not": false,
		//                                 "data": "https://123-transporter.cz/",
		//                                 "repeat": null,
		//                                 "timesearch": null,
		//                                 "visitsCountSearch": null
		//                             },
		//                             {
		//                                 "entid": "50",
		//                                 "data": "https://stag.123-transporter.at/",
		//                                 "not": false,
		//                                 "compid": "4",
		//                                 "repeat": null,
		//                                 "timesearch": null,
		//                                 "visitsCountSearch": null
		//                             }
		//                         ]
		//                     ]
		//                 ],
		//                 "n": "Location #1004102126"
		//             }
		//         },
		//         "comparisons": {
		//             "1": {
		//                 "comparison_id": "1",
		//                 "module_name": "equal"
		//             },
		//             "2": {
		//                 "comparison_id": "2",
		//                 "module_name": "less"
		//             },
		//             "3": {
		//                 "comparison_id": "3",
		//                 "module_name": "lessEqual"
		//             },
		//             "4": {
		//                 "comparison_id": "4",
		//                 "module_name": "matches"
		//             },
		//             "5": {
		//                 "comparison_id": "5",
		//                 "module_name": "regeMatches"
		//             },
		//             "6": {
		//                 "comparison_id": "6",
		//                 "module_name": "contains"
		//             },
		//             "7": {
		//                 "comparison_id": "7",
		//                 "module_name": "endsWith"
		//             },
		//             "8": {
		//                 "comparison_id": "8",
		//                 "module_name": "startsWith"
		//             },
		//             "9": {
		//                 "comparison_id": "9",
		//                 "module_name": "isIn"
		//             },
		//             "10": {
		//                 "comparison_id": "10",
		//                 "module_name": "equal"
		//             },
		//             "11": {
		//                 "comparison_id": "11",
		//                 "module_name": "commaSepStringsContains"
		//             },
		//             "12": {
		//                 "comparison_id": "12",
		//                 "module_name": "contains"
		//             }
		//         },
		//         "_s_t": "2025-01-14 08:07:47Z"
		//     },
		//     "currentData": {
		//         "goals": {
		//             "100464662": 1
		//         },
		//         "experiments": {
		//             "1004122960": {
		//                 "variation_id": "1004291683",
		//                 "variation_name_parts": {
		//                     "sections": [
		//                         ""
		//                     ],
		//                     "changes": [
		//                         "Version 2"
		//                     ]
		//                 },
		//                 "variation_name": "Var #1004291683",
		//                 "first_time": true
		//             },
		//             "1004124806": {
		//                 "variation_id": "1004295894",
		//                 "variation_name_parts": {
		//                     "sections": [
		//                         ""
		//                     ],
		//                     "changes": [
		//                         "Version 1"
		//                     ]
		//                 },
		//                 "variation_name": "Var #1004295894",
		//                 "first_time": true
		//             }
		//         },
		//         "experiments_goals": {
		//             "1004122960": {
		//                 "100464662": 1
		//             },
		//             "1004124806": {
		//                 "100464662": 1
		//             }
		//         }
		//     },
		//     "historicalData": {
		//         "experiments": {
		//             "1004122960": {
		//                 "variation_name": "Var #1004291683",
		//                 "variation_id": "1004291683",
		//                 "goals": {
		//                     "100464662": 1
		//                 }
		//             },
		//             "1004124806": {
		//                 "variation_name": "Var #1004295894",
		//                 "variation_id": "1004295894",
		//                 "goals": {
		//                     "100464662": 1
		//                 }
		//             }
		//         }
		//     }
		// }
	},

	methods: {
		onScroll(e: any): void {
			this.offset_top = e.target.scrollingElement.scrollTop;
		},

		async changeLocale(locale: string): Promise<void> {
			this.UTILS.setPageLoader(true);

			sessionStorage.setItem('lang', locale);
			await this.loadLocaleMessagesAsync(locale);

			this.UTILS.setPageLoader(false);
		},

		getParamsFromURL() {
			const urlParams = new URLSearchParams(window.location.search);
			return urlParams.get('lang');
		},

		getDiscountCode() {
			const params = new URLSearchParams(window.location.search);
			return params.get('dc');
		}
	}
});
