
import Vue from 'vue';

export default Vue.extend({
	name: 'AlertDialog',
	props: {
		title: {
			type: String
		},
		message: {
			type: String,
			required: true
		},

		color: {
			type: String,
			default: 'primary'
		}
	},

	data() {
		return {
			show: true
		};
	},

	computed: {
		isContactDialog(): boolean {
			return this.title === (this.$t('general.contact') as string);
		}
	},

	methods: {
		clickOK(): void {
			this.show = false;
			this.$emit('click', true);
		}
	}
});
