import Vue from 'vue';

const cookieConsent = Vue.extend({
	data() {
		return {
			googleMapsConsent: false,
			youtubeConsent: false
		};
	},

	mounted() {
		this.updateGoogleMapsConsent();
		if (process.client) {
			window.addEventListener('ccm19CookieAccepted', this.updateGoogleMapsConsent);
			window.addEventListener('ccm19CookieDeclined', this.updateGoogleMapsConsent);
			window.addEventListener('ccm19EmbeddingAccepted', this.updateGoogleMapsConsent);
			window.addEventListener('ccm19WidgetClosed', this.shouldBeReloaded);
		}
	},

	computed: {
		isGoogleMapsConsentGiven(): boolean {
			return this.googleMapsConsent;
		},
		isYoutubeConsentGiven(): boolean {
			return this.youtubeConsent;
		}
	},

	beforeDestroy() {
		if (process.client) {
			window.removeEventListener('ccm19CookieAccepted', this.updateGoogleMapsConsent);
			window.removeEventListener('ccm19CookieDeclined', this.updateGoogleMapsConsent);
			window.removeEventListener('ccm19EmbeddingAccepted', this.updateGoogleMapsConsent);
			window.removeEventListener('ccm19WidgetClosed', this.shouldBeReloaded);
		}
	},

	methods: {
		openCookieConsent() {
			if (process.client) {
				if (typeof CCM !== 'undefined') {
					CCM.openWidget();
				}
			}
		},

		openGoogleMapsConsent() {
			if (process.client) {
				if (typeof CCM !== 'undefined' && CCM.openDetailsWindowAtEmbedding) {
					CCM.openDetailsWindowAtEmbedding('66b1f56216ce50d1700cacf2');
				}
			}
		},

		updateGoogleMapsConsent(): void {
			if (process.client) {
				if (typeof CCM !== 'undefined' && CCM.acceptedEmbeddings) {
					const currentEmbeddings = CCM.acceptedEmbeddings.length;

					if (localStorage) {
						localStorage.setItem('acceptedEmbeddingsLength', currentEmbeddings.toString());
					}

					this.googleMapsConsent = CCM.acceptedEmbeddings.some((embedding: any) => embedding.name === 'Google Maps');
					this.youtubeConsent = CCM.acceptedEmbeddings.some((embedding: any) => embedding.name === 'YouTube Video');
				} else {
					this.googleMapsConsent = false;
					this.youtubeConsent = false;
				}
			}
		},

		shouldBeReloaded() {
			if (process.client) {
				let currentEmbeddingsLength;

				if (localStorage) {
					currentEmbeddingsLength = localStorage.getItem('acceptedEmbeddingsLength');
				}

				const newEmbeddingsLength = CCM.acceptedEmbeddings.length.toString();

				if (currentEmbeddingsLength !== newEmbeddingsLength) {
					localStorage.setItem('acceptedEmbeddingsLength', newEmbeddingsLength);
					window.location.reload();
				}
			}
		}
	}
});

export default cookieConsent;
