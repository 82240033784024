/* eslint-disable prefer-regex-literals */
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n/types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { VForm } from '@/types';

import { inputLimit } from '@/_helpers/misc_helper';
import country_iban_lengths from '@/_helpers/countries_iban_length';
import MISC_DATA from '@/store/modules/MiscDataModule';

// ----------- Local variables & functions -----------
const emailRegex = RegExp(
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const phoneRegex = RegExp(/^\+[0-9 ]*$/);

const dateRegex = new RegExp(
	/^(?:0[1-9]|[12][0-9]|3[01])[.](?:0[1-9]|1[012])[.](?:19\d{2}|20\d{2})$/
);

function checkImageMaxSize(file: File): undefined | boolean {
	if (!file) {
		return true; // There's no file so don't check. (Visible when the only rule)
	}
	const maxSize = 1024 * 1024 * 5; // 5mb
	return file.size <= maxSize;
}

function checkImageFormat(file: File): undefined | boolean {
	if (!file) {
		return;
	}
	const formatAccepted = ['heic', 'heif', 'jpeg', 'png'];
	const [format, type] = file.type.split('/');
	if (format === 'image' && type) {
		return formatAccepted.includes(type);
	}
}

function checkEmail(email: string) {
	if (email.includes('@gmail')) {
		return emailRegex.test(email) && email.endsWith('@gmail.com');
	}
	return emailRegex.test(email);
}

const formHelper = Vue.extend({
	data() {
		return {
			inputLimit: inputLimit,
			country: {} as any
		};
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		titles(): { title: string; code: string }[] {
			const titles = this.MISC_DATA.titles.map((t) => {
				t.title = this.$t('user.title.' + t.code.toLowerCase()) as string;
				return t;
			});

			return titles;
		},

		countryList(): { country: string; code: string }[] {
			const ctyList = this.MISC_DATA.countries.map((cty) => {
				cty.name = this.$t('countries.' + cty.code.toLowerCase()) as string;
				return {
					country: cty.name,
					code: cty.code
				};
			});

			ctyList.sort((a, b) => (a.country > b.country ? 1 : -1));
			ctyList.sort((a, b) => (b && a.code === this.$getDomain().toUpperCase() ? -1 : 1));

			return ctyList;
		},

		fullCountryList(): { code: string; country: string }[] {
			const list = this.$t('countries') as any;
			const countries = [];

			for (const x in list) {
				countries.push({ code: x, country: list[x] });
			}

			return countries;
		},

		countryCodeList(): string[] {
			const list = [] as string[];
			this.countryList.forEach((i) => {
				list.push(i.code);
			});
			return list;
		},

		rules() {
			return {
				required: (v: string | boolean) =>
					!!v || (this.$t('alert.validation.required') as TranslateResult),
				email: (v: string) =>
					checkEmail(v) || (this.$t('alert.validation.invalid_email') as TranslateResult),
				phone: (v: any) =>
					phoneRegex.test(v) ||
					(this.$t('alert.validation.invalid_phone') as TranslateResult),
				date: (v: any) => {
					if (v) {
						return (
							dateRegex.test(v) ||
							(this.$t('alert.validation.invalid_date') as TranslateResult)
						);
					} else {
						return true;
					}
				},
				iban: (v: any) =>
					this.checkIBAN(v) ||
					(this.$t('alert.validation.invalid_iban') as TranslateResult),
				magic: (v: any) =>
					emailRegex.test(v) ||
					phoneRegex.test(v) ||
					(this.$t('alert.validation.invalid_email_or_phone') as TranslateResult),
				file: {
					max_size: (v: File) =>
						checkImageMaxSize(v) ||
						(this.$t('alert.validation.file_too_large') as TranslateResult),
					format: (v: File) =>
						checkImageFormat(v) ||
						(this.$t('alert.validation.file_wrong_format') as TranslateResult)
				},
				agree: {
					general: (v: any) =>
						!!v || (this.$t('alert.validation.agreement.general') as TranslateResult),
					privacy: (v: any) =>
						!!v || (this.$t('alert.validation.agreement.privacy') as TranslateResult),
					tos: (v: any) =>
						!!v || (this.$t('alert.validation.agreement.tos') as TranslateResult),
					abo_tos: (v: any) =>
						!!v || (this.$t('alert.validation.agreement.abo_tos') as TranslateResult),
					abo_revoke: (v: any) =>
						!!v || (this.$t('alert.validation.agreement.abo_revoke') as TranslateResult)
				}
			};
		}
	},

	methods: {
		scrollToError(form: VForm, dialog?: boolean): void {
			this.$logger.console({
				message: 'ScrollToError in formHelper',
				data: { form: form, error_bag: form.errorBag }
			});
			for (const i in form.errorBag) {
				if (form.errorBag[i] === true) {
					const el = form.$children.find((c) => {
						return (c as any)._uid === i;
					});
					if (el !== undefined) {
						if (dialog) {
							el.$el.scrollIntoView({ behavior: 'smooth' });
						}
						this.$vuetify.goTo(el.$el as HTMLElement, { offset: 10 }); // 64px height of navBar
						break;
					}
				}
			}
		},

		intValidation: (value: any) => {
			try {
				const phoneNumber = parsePhoneNumberFromString(value);
				return phoneNumber?.isValid() || 'Invalid phone number';
			} catch (error) {
				return 'Invalid phone number';
			}
		},

		createPostcodeRule(country: any) {
			const countries = JSON.parse(JSON.stringify(MISC_DATA.countries));
			let countryExp = null;
			for (let c = 0; c < countries.length; c++) {
				if ((countries as any)[c].code === country.code) {
					countryExp = (countries as any)[c].pcp;
				}
			}
			const rule = '^(' + countryExp + ')$';
			const reg = new RegExp(rule);
			return (v: any) => reg.test(v) || this.$t('alert.validation.invalid_postcode');
		},

		createMinDateRule(minDate: string) {
			return (v: any) => {
				const [day, month, year] = v.split('.');
				const format = `${year}-${month}-${day}`;
				return (
					format >= minDate ||
					this.$t('alert.validation.invalid_date_min', {
						date: minDate.split('-').reverse().join('.')
					})
				);
			};
		},

		checkIBAN(input: string): boolean {
			if (!input) {
				return true;
			}
			const iban = String(input)
				.toUpperCase()
				.replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
			const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
			let digits = '';
			// check syntax and length
			if (!code || iban.length !== country_iban_lengths[code[1]]) {
				return false;
			}
			// rearrange country code and check digits, and convert chars to ints
			digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
				return (letter.charCodeAt(0) - 55) as any;
			});
			// final check
			return this.mod97(digits) === 1;
		},

		mod97(str: string) {
			let checksum = str.slice(0, 2) as any;
			let fragment = '';
			for (let offset = 2; offset < str.length; offset += 7) {
				fragment = String(checksum) + str.substring(offset, offset + 7);
				checksum = parseInt(fragment, 10) % 97;
			}
			return checksum;
		}
	}
});

export default formHelper;
